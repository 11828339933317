/* all */
/* home */
/* Stili generali per la scrollbar */
::-webkit-scrollbar {
  width: 14.5px; /* Larghezza della scrollbar */
}

::-webkit-scrollbar-track {
  background: #2b2b2b; /* Colore dello sfondo della scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #dfdfdf; /* Colore del "thumb" della scrollbar (parte mobile) */
  border-radius: 7px; /* Bordo arrotondato */
  border: 3px solid #2b2b2b; /* Aggiungi un bordo per creare un contrasto */
}

/* Stili per la scrollbar in modalità dark */
body.dark-mode ::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* Colore bianco per il thumb in modalità dark */
}

/* affari game */

/* 
end affarigame
 */
/*  */
/*
affari table
  */ /* TabellaAffariTuoi.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #191920;
  font-family: "Inter", sans-serif;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.formContainer {
  width: 100%;
  padding: 1.3em;
  background-color: #191920;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5em;
  position: fixed;
  top: 0;
  flex-wrap: wrap;
  margin: 0 auto;
  /*  margin-top: 10px; */
}

.button {
  background-color: #ffffff00;
  color: white;
  padding: 1.1em 3em;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  border: 2px currentColor solid; /* Assicurati che currentColor prenda il valore corretto */
  box-shadow: 0px 0px 10px currentColor, 0px 0px 10px currentColor inset;
  -webkit-box-shadow: 0px 0px 10px currentColor, 0px 0px 10px currentColor inset;
  transition: box-shadow 0.3s;
}

.button:hover {
  box-shadow: 0px 0px 15px currentColor, 0px 0px 15px currentColor inset;
  -webkit-box-shadow: 0px 0px 15px currentColor, 0px 0px 15px currentColor inset;
}

.button:active {
  box-shadow: 0px 0px 20px currentColor, 0px 0px 20px currentColor inset;
  -webkit-box-shadow: 0px 0px 20px currentColor, 0px 0px 20px currentColor inset;
}

/* 
*/

.input_premio {
  color: white;
  padding: 1em;
  margin: 0.4em;
  border: 2px solid currentColor;
  background-color: #ffffff00;
  border-radius: 20px;
  flex: 1;
  min-width: 150px;
  max-width: 300px;
  box-sizing: border-box;
  outline: none;
  caret-color: currentColor;
  border-color: currentColor;
  -webkit-box-shadow: 0px 0px 10px currentColor, 0px 0px 10px currentColor inset;
  box-shadow: 0px 0px 10px currentColor, 0px 0px 10px currentColor inset;
}

.tableContainer {
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  margin-top: 7em;
  margin-bottom: 150%;
}

.tableContainer::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

table {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden; /*  */ /*  */ /*  */ /*  */ /*  */ /*  */ /*  */
  color: white;
  text-align: center;
  table-layout: fixed;
}

.check_shuffle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 19px;
  height: 19px;
  border: 2px solid currentColor;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 0px 10px currentColor, 0px 0px 10px currentColor inset;
  -webkit-box-shadow: 0px 0px 10px currentColor, 0px 0px 10px currentColor inset;
  transition: box-shadow 0.3s, background-color 0.3s;
}

.check_shuffle:hover {
  box-shadow: 0px 0px 15px currentColor, 0px 0px 11px currentColor inset;
  -webkit-box-shadow: 0px 0px 15px currentColor, 0px 0px 11px currentColor inset;
}

.check_shuffle:active {
  box-shadow: 0px 0px 20px currentColor, /* 0px 0px 20px currentColor inset */;
  -webkit-box-shadow: 0px 0px 20px currentColor;
}

.check_shuffle:checked {
  background-color: currentColor;
  box-shadow: 0px 0px 15px currentColor, /* 0px 0px 15px currentColor inset */;
  -webkit-box-shadow: 0px 0px 15px currentColor,
    /*  0px 0px 15px currentColor inset */;
}
.check_shuffle:checked::after {
  content: "\2713"; /* Unicode per il segno di spunta */
  padding-left: 1.5px;
  font-size: 14px;
  color: white; /* Colore del segno di spunta */
  font-weight: bold;
}
@media (orientation: landscape) {
  .tableContainer {
    /* Adjust the height in landscape mode */
    position: static;
    padding-bottom: 90px; /* Adjust padding for landscape */
  }
}
@media (max-height: 620px) {
  .tableContainer {
    max-height: 60vh;
  }
  .playButton {
    padding: 10px 0;
    font-size: 1em;
  }
}
@media (max-height: 450px) {
  .tableContainer {
    max-height: 55vh;
  }
}
@media (max-height: 380px) {
  .tableContainer {
    max-height: 44vh;
  }
}

@media (max-width: 620px) {
  .formContainer {
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }

  .column {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5em;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }
  .container {
    padding-top: 100px;
  }
  .tableContainer {
    max-height: 40vh;
    /*     overflow-y: auto;
 */
    padding-bottom: 260px;
  }

  .button {
    width: 100%;
    max-width: 150px;
    margin: 0px;
  }

  .input_premio {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  /* .column {
    gap: 0.5em;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  } */
  .formContainer {
    padding: 5px;
  }

  .button {
    padding: 8px 16px;
    margin: 3px;
  }
  .tableContainer {
    margin-top: 80px;

    max-height: 35vh;
  }

  .playButton {
    padding: 10px 0;
    font-size: 1em;
  }
}
@media (max-width: 402px) {
  .tableContainer {
    /*     margin-top: 30px;
 */
    max-height: 30vh;
  }
}

/* 
 */
/* end affari table */
/* 
 */

/* 
 /* game affari*/
.prizes_game::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.prizes_game {
  display: flex;
  flex-wrap: wrap;
  max-height: 15em;
  overflow-y: auto;
  justify-content: space-around;
}
@media (max-height: 600px) {
  .prizes_game {
    max-height: 6.8em;
  }
}

/* end game affari */

* {
  margin: 0;
  padding: 0;
}
html,
body {
  overflow: hidden;
}
/* Viewer */
.wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #191920;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.card {
  height: 70vh;
  border-radius: 13px;
  box-shadow: 20px 40px 33px rgba(0, 0, 0, 0.3);

  padding: 2rem;
  width: 55vw;
  background-color: #5959704a;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-canvas {
  width: 96%;
  height: 83%;
  background: white;
  box-shadow: 0px 0px 15px 12px #ffffff;
  border-radius: 16px;
  margin-bottom: 20px;
  margin-top: 5px;
}

@media (max-width: 1009px) {
  .card {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .product-canvas {
    width: 100%;
  }
}
.button-container {
  height: 17%;
  margin-top: 16px;
  display: flex;
  gap: 10px; /* Spazio tra i pulsanti */
  align-items: center;
}
.buttonColors {
  padding: 37px 40px;
  border-radius: 10px;
  border: 0;
  background-color: currentColor;
  transition: all 0.3s ease;
  box-shadow: black 0px 20px 0px 0px, white inset 0px 20px 30px -19px;
  /* white inset 0px 10px 10px -4px; */
  cursor: pointer;
}

.buttonColors:hover {
  transform: translateY(6px);
  box-shadow: black 0px 14px 0px 0px, white inset 0px 23px 30px -19px;
  transition: all 0.3s ease;
}

.buttonColors:active {
  box-shadow: black 0px 0px 0px 0px, white inset 0px 25px 30px -19px;
  transform: translateY(20px);
  transition: 200ms;
}
@media (hover: none) {
  .buttonColors {
    box-shadow: black 0px 20px 0px 0px;
  }
  .buttonColors:hover {
    transform: translateY(6px);
    box-shadow: black 0px 14px 0px 0px;
    transition: all 0.3s ease;
  }

  .buttonColors:active {
    box-shadow: black 0px 0px 0px 0px;
    transform: translateY(20px);
    transition: 200ms;
  }
}

@media (max-height: 500px) {
  .buttonColors {
    padding: 27px 30px;
  }
}
@media (max-height: 415px) {
  .buttonColors {
    padding: 17px 20px;
  }
}
/* max height 312 max width 365  */
@media (max-width: 600px) {
  .buttonColors {
    padding: 27px 30px;
  }
}

@media (max-width: 485px) {
  .buttonColors {
    padding: 22px 25px;
  }
}
@media (max-width: 415px) {
  .buttonColors {
    padding: 17px 20px;
  }
}
/* HOME */

.homeCanvas {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  background-color: #191920;
  touch-action: none;
}

.home {
  background-color: #3891b4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100); /* Usa la variabile vh per altezza */
  width: 100%;
}
.tour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Imposta l'altezza al 100% della viewport height */
}
.tourCanvas {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  touch-action: none;
}
.htmlScreen iframe {
  width: 1024px;
  height: 670px;
  border: none;
  border-radius: 20px;
  background: #000000;
}

.canvas_affari {
  width: 55vw;
  height: 58vh;
  background: white;
  box-shadow: 10px 15px 15px 6px #3891b4;
  border-radius: 0px;
  margin-bottom: 20px;
}
